import React, { Fragment } from 'react';
import { Parallax } from 'react-parallax';

const ParallaxWrapper = (props) => (
	<Parallax {...props}>
		{props.children}
	</Parallax>
)

export default ParallaxWrapper;

// Docs
// https://github.com/civiccc/react-parallax
