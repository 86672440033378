import React, { useState } from 'react';
import Waypoint from 'src/interface/vendors/waypoint-wrapper';
import { NextLink } from 'src/interface/basics/links';
import { Container, Row, Col } from 'src/interface/basics/grids';
import { Prefooter, PrefooterBlock, PrefooterBody, PrefooterPreTitle, PrefooterTitle, PrefooterText, PrefooterBtns } from 'src/interface/basics/prefooters';

const MktPrefooter = ({ className, children }) => {
  const [ animation, setAnimation ] = useState('');

  return (
    <Waypoint bottomOffset='30%' onEnter={() => setAnimation('fade-in-up')}>
      <section className='prefooter-section'>
        <Container className='lg'>
          <PrefooterBlock className={`gradient-primary-accent ${className}`}>
            <Row className='flex-center'>
              <Col sm={12} md={7}>
                <PrefooterBody className={`animate ${animation} delay-200`}>
                  <PrefooterPreTitle>Free Estimate</PrefooterPreTitle>
                  <PrefooterTitle>Ready to Get Started?</PrefooterTitle>
                  <PrefooterText>Contact our team to schedule a brief discovery phone call and get a free estimate for your project.</PrefooterText>
                </PrefooterBody>
              </Col>
              <Col sm={12} md={5}>
                <PrefooterBtns className={`animate ${animation} delay-600`}>
                  <NextLink to='/contact' className='btn btn-opaque-white btn-lg'>Contact us</NextLink>
                </PrefooterBtns>
              </Col>
            </Row>
          </PrefooterBlock>
        </Container>
      </section>
    </Waypoint>
  );
}

export default MktPrefooter;
