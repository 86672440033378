import React, { Fragment } from 'react';
import Parallax from 'src/interface/vendors/parallax-wrapper';
import { FadIcon } from 'src/interface/vendors/fa-wrapper';

const Header = ({ className = '', children }) => (
  <header className={`header-block ${className}`}>
    {children}
  </header>
)

const HeaderInner = ({ className = '', children }) => (
  <div className={`header-inner ${className}`}>
    {children}
  </div>
)

const HeaderBg = ({ background, className = '', children }) => (
  <Parallax className={`header-bg ${className}`} bgImage={background} strength={300}>
    {children}
    <HeaderOverlay />
  </Parallax>
)

const HeaderBody = ({ className = '', children }) => (
  <div className={`header-body ${className}`}>
    {children}
  </div>
)

const HeaderPretitle = ({ className = '', children }) => (
  <h6 className={`header-pretitle ${className}`}>
    {children}
  </h6>
)

const HeaderTitle = ({ className = '', children }) => (
  <h1 className={`header-title ${className}`}>
    {children}
  </h1>
)

const HeaderSubtitle = ({ className = '', children }) => (
  <h5 className={`header-subtitle ${className}`}>
    {children}
  </h5>
)

const HeaderText = ({ className = '', children }) => (
  <h5 className={`header-text ${className}`}>
    {children}
  </h5>
)

const HeaderBtns = ({ className = '', children }) => (
  <div className={`header-btns ${className}`}>
    {children}
  </div>
)

const HeaderOverlay = ({ className = '', children }) => (
  <div className={`header-overlay ${className}`}>
    {children}
  </div>
)

export {
  Header,
  HeaderInner,
  HeaderBg,
  HeaderBody,
  HeaderPretitle,
  HeaderTitle,
  HeaderSubtitle,
  HeaderText,
  HeaderBtns,
  HeaderOverlay
}
