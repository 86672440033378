import React from 'react';
import { Link } from 'gatsby';
import { ButtonWrapper } from 'src/interface/basics/buttons';
import { FalIcon } from 'src/interface/vendors/fa-wrapper';

const Prefooter = ({ className = '', children }) => (
	<section className={`prefooter-section ${className}`}>
		{children}
	</section>
)

const PrefooterBlock = ({ className = '', children }) => (
  <div className={`prefooter-block ${className}`}>
    {children}
  </div>
)

const PrefooterBody = ({ className = '', children }) => (
  <div className={`prefooter-body ${className}`}>
    {children}
  </div>
)

const PrefooterPreTitle = ({ className = '', children }) => (
  <h6 className={`prefooter-pretitle ${className}`}>
    {children}
  </h6>
)

const PrefooterTitle = ({ className = '', children }) => (
  <h2 className={`prefooter-title ${className}`}>
    {children}
  </h2>
)

const PrefooterSubtitle = ({ className = '', children }) => (
  <h2 className={`prefooter-title ${className}`}>
    {children}
  </h2>
)

const PrefooterText = ({ className = '', children }) => (
  <h5 className={`prefooter-text ${className}`}>
    {children}
  </h5>
)

const PrefooterBtns = ({ className = '', children }) => (
  <div className={`prefooter-btns ${className}`}>
    {children}
  </div>
)

export {
	Prefooter,
	PrefooterBlock,
	PrefooterBody,
	PrefooterPreTitle,
	PrefooterTitle,
	PrefooterText,
	PrefooterBtns
}

